<template>
  <Modal
    id="HistoricSearchModal"
    :isOpen.sync="isModalOpen"
    :header="`${$t('dispatch.searchHReport')} ${date}`"
    size="is-large"
    :buttonLabelClose="$t('global.close')"
  >
    <InputSearch
      class="mb-4"
      :label="$t('global.orderNumber')"
      :placeholder="`${$t('global.search')}...`"
      @input="onSearch"
      :value="searchQuery"
      focus
    />
    <Table
      v-if="params.length"
      class="Table"
      :perPage="10"
      :show-detail-icon="false"
      :params="params"
      apiUrl="delivery_manifest/delivery_events/search"
      infiniteScroll
      paramsRequired
    >
      <b-table-column :label="$t('global.orderNumber')" v-slot="props">
        {{ props.row.order_number }}
      </b-table-column>
      <b-table-column :label="$t('participant.name')" v-slot="props">
        {{ props.row.full_name }}
      </b-table-column>
      <b-table-column :label="$t('global.address')" v-slot="props">
        {{ props.row.destination_full_address }}
      </b-table-column>
      <b-table-column v-slot="props">
        <Button
          :tooltip="$t('dispatch.downloadDeliveryReport')"
          :permission="Permission.report"
          type="is-primary"
          icon-left="file-download"
          @click="() => onDownloadDeliveryReport(props.row)"
        />
      </b-table-column>
    </Table>
    <Empty class="historicEmpty" v-else />
  </Modal>
</template>

<script>
import { Button, Empty, InputSearch, Modal, Table } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button,
    Empty,
    InputSearch,
    Modal,
    Table
  },
  beforeDestroy() {
    this.isModalOpen = false;
  },
  data() {
    return {
      isModalOpen: this.isOpen,
      searchQuery: ''
    };
  },
  computed: {
    params() {
      return this.searchQuery ? [`date=${this.date}`, `order_number=${this.searchQuery}`] : [];
    },
    Permission() {
      return Permissions.Dispatch;
    }
  },
  methods: {
    onDownloadDeliveryReport({ id: eventId }) {
      let { href } = this.$router.resolve({ name: 'deliveryReport', params: { eventId } });
      window.open(href, '_blank');
    },
    onSearch(query) {
      this.searchQuery = query;
    }
  },
  watch: {
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
      if (!value) this.searchQuery = '';
    },
    isOpen(value) {
      this.isModalOpen = value;
    }
  },
  props: {
    date: { type: String, default: '' },
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#HistoricSearchModal
  ::v-deep
    .modal-content-container
      min-height: 608px
.Table,
.historicEmpty
  height: calc( 100% - 100px )
</style>
