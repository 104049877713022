<template>
  <div
    class="svg-icon arrow-plus-up"
    :class="disabled ? 'svg-icon-disabled' : ''"
    :style="cssHeight"
    @click.stop="$emit('click')"
  >
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5925 21V9.82998L15.4725 14.71C15.8625 15.1 16.5025 15.1 16.8925 14.71C17.2825 14.32 17.2825 13.69 16.8925 13.3L10.3025 6.70998C9.9125 6.31998 9.2825 6.31998 8.8925 6.70998L2.2925 13.29C1.9025 13.68 1.9025 14.31 2.2925 14.7C2.6825 15.09 3.3125 15.09 3.7025 14.7L8.5925 9.82998V21C8.5925 21.55 9.0425 22 9.5925 22C10.1425 22 10.5925 21.55 10.5925 21Z"
        fill="black"
      />
      <path
        d="M21.5925 5H19.5925V3C19.5925 2.45 19.1425 2 18.5925 2C18.0425 2 17.5925 2.45 17.5925 3V5H15.5925C15.0425 5 14.5925 5.45 14.5925 6C14.5925 6.55 15.0425 7 15.5925 7H17.5925V9C17.5925 9.55 18.0425 10 18.5925 10C19.1425 10 19.5925 9.55 19.5925 9V7H21.5925C22.1425 7 22.5925 6.55 22.5925 6C22.5925 5.45 22.1425 5 21.5925 5Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import { cssHeight } from '.';

export default {
  computed: {
    cssHeight() {
      return cssHeight(this.height);
    }
  },
  props: {
    alt: { type: String, default: 'icon-svg' },
    disabled: { type: Boolean, default: false },
    fit: { type: String, default: 'cover' },
    height: { type: Number, default: 24 },
    src: { type: String, default: null },
    width: { type: Number, default: 24 }
  }
};
</script>

<style lang="sass" scoped>
.svg-icon
  &-disabled
    pointer-events: none
    svg path
      fill: $gray-500!important
  svg path
    fill: $primary
  &:hover
    svg path
      fill: darken($primary, 10)
.dark .svg-icon
  svg path
    fill: $primary-dark
  &:hover
    svg path
      fill: $secondary-active-dark
</style>
