<template>
  <div
    class="svg-icon arrow-plus-down"
    :class="disabled ? 'svg-icon-disabled' : ''"
    :style="cssHeight"
    @click.stop="$emit('click')"
  >
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59256 7.4175L9.59256 18.5875L4.71256 13.7075C4.32256 13.3175 3.68256 13.3175 3.29256 13.7075C2.90256 14.0975 2.90256 14.7275 3.29256 15.1175L9.88256 21.7075C10.2726 22.0975 10.9026 22.0975 11.2926 21.7075L17.8926 15.1275C18.2826 14.7375 18.2826 14.1075 17.8926 13.7175C17.5026 13.3275 16.8726 13.3275 16.4826 13.7175L11.5926 18.5875L11.5926 7.4175C11.5926 6.8675 11.1426 6.4175 10.5926 6.4175C10.0426 6.4175 9.59256 6.8675 9.59256 7.4175Z"
        fill="black"
      />
      <path
        d="M20.5925 6H18.5925V4C18.5925 3.45 18.1425 3 17.5925 3C17.0425 3 16.5925 3.45 16.5925 4V6H14.5925C14.0425 6 13.5925 6.45 13.5925 7C13.5925 7.55 14.0425 8 14.5925 8H16.5925V10C16.5925 10.55 17.0425 11 17.5925 11C18.1425 11 18.5925 10.55 18.5925 10V8H20.5925C21.1425 8 21.5925 7.55 21.5925 7C21.5925 6.45 21.1425 6 20.5925 6Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
import { cssHeight } from '.';

export default {
  computed: {
    cssHeight() {
      return cssHeight(this.height);
    }
  },
  props: {
    alt: { type: String, default: 'icon-svg' },
    disabled: { type: Boolean, default: false },
    fit: { type: String, default: 'cover' },
    height: { type: Number, default: 24 },
    src: { type: String, default: null },
    width: { type: Number, default: 24 }
  }
};
</script>
<style lang="sass" scoped>
.svg-icon
  &-disabled
    pointer-events: none
    svg path
      fill: $gray-500!important
  svg path
    fill: $primary
  &:hover
    svg path
      fill: darken($primary, 10)
.dark .svg-icon
  svg path
    fill: $primary-dark
  &:hover
    svg path
      fill: $secondary-active-dark
</style>
