<template>
  <Modal
    :buttonLabelClose="$t('global.close')"
    :header="$t('dispatch.bypass')"
    :isOpen.sync="isModalOpen"
    :loading="loading"
    :saveDisabled="saveDisable"
    @cancel="() => {}"
    @save="onSubmit"
    id="BypassModal"
    size="is-medium"
  >
    <MessageBadge type="is-advice" :message="$t('bypass.bypassMessageBadge')">
      <strong class="ml-1 mr-1">{{ $t('global.note') }}:</strong>
      <p>{{ $t('bypass.bypassMessageBadge') }}</p>
    </MessageBadge>
    <Autocomplete
      :api="{
        url: 'catalogs/tablet/imei',
        full: true
      }"
      :label="$t('global.tablet')"
      :placeholder="$t('global.selectTablet')"
      class="required mb-4 mt-2"
      field="device_name"
      model="imei"
      required
      v-model="oBypass.tablet"
    />

    <Autocomplete
      :api="{
        url: 'vehicles',
        full: true
      }"
      :label="$t('global.vehicle')"
      :placeholder="$t('global.selectVehicle')"
      class="required mb-2 mt-5"
      field="name"
      model="id"
      required
      v-model="oBypass.vehicle"
    />
  </Modal>
</template>

<script>
import { Modal, Autocomplete, MessageBadge } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Modal,
    Autocomplete,
    MessageBadge
  },
  data() {
    return {
      isModalOpen: this.isOpen,
      oBypass: {
        tablet: null,
        vehicle: null
      },
      loading: {
        save: false
      }
    };
  },
  computed: {
    payload() {
      const { tablet, vehicle } = this.oBypass;

      return {
        vehicle_id: vehicle,
        imei: tablet
      };
    },
    Permission() {
      return Permissions.Dispatch;
    },
    saveDisable() {
      return !this.oBypass.tablet || !this.oBypass.vehicle;
    },
    validation() {
      return !!this.oBypass.tablet || !!this.oBypass.vehicle;
    }
  },
  methods: {
    async onSubmit() {
      this.loading.save = true;
      if (this.validation) {
        try {
          await this.Api.post(`/employees/${this.employeeId}/bypass_start_work`, this.payload);
          this.$toast('success', this.$t('messages.saved'), 5000);
          this.$emit('success');
          this.isModalOpen = false;
        } catch (error) {
          console.log(error);
        }
      }
      this.loading.save = false;
    },
    onClear() {
      this.oBypass = {
        tablet: null,
        vehicle: null
      };
    }
  },
  watch: {
    isModalOpen(value) {
      if (!value) this.onClear();
      this.$emit('update:isOpen', value);
    },
    isOpen(value) {
      this.isModalOpen = value;
    }
  },
  props: {
    employeeId: { type: Number, default: null },
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#BypassModal
  ::v-deep
    .modal-content-container .slot
      overflow: visible !important
</style>
