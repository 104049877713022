import Api from '@/utils/Api.js';
import i18n from '@/locales';
import { confirm } from '../utils/dialog';

export const handlerAddPickup = async (params, override) => {
  const { delivery_manifest_id, close_door_pharmacy_id, secuence } = params;
  try {
    const { data } = await Api.post(
      `/delivery_manifest/${delivery_manifest_id}/delivery_events/pick_up${
        override ? '/override' : ''
      }`,
      {
        close_door_pharmacy_id,
        secuence
      }
    );
    return data;
  } catch (error) {
    const overrideConfirmation = async () =>
      new Promise((resolve) => {
        confirm({
          title: i18n.t('confirms.continue'),
          message: i18n.t('schedule.confirm.overtime'),
          cancelText: i18n.t('confirm.no'),
          confirmText: i18n.t('confirm.yes'),
          onCancel: () => resolve(false),
          onConfirm: async () => {
            const data = await handlerAddPickup(params, true);
            resolve(data);
          }
        });
      });

    if (error?.data?.status_code === 'dsch049') {
      const result = await overrideConfirmation();
      return result;
    }
    return false;
  }
};
export const handlerRemovePickup = async (params) => {
  const { manifestId, deliveryId } = params;
  const { data } = await Api.delete(
    `/delivery_manifest/${manifestId}/delivery_events/${deliveryId}/pick_up`
  );
  return data;
};
