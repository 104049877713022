<template>
  <div id="unattended-deliveries-table">
    <Deliveries :isOpen.sync="showDeliveryModal" :delivery="editRowData" @save="getData" />
    <Table
      :header="dispatchView ? '' : $t('schedule.mainTableTitle')"
      :focusable="false"
      :loading.sync="oLoading.table"
      :perPage="40"
      :show-detail-icon="false"
      :checked-rows.sync="checkedRows"
      :apiUrl="searchApiUrl"
      :dataPreProcessor="deliveriesDataPreProcessor"
      :params="aParams"
      :is-row-checkable="isCheckable"
      class="schedule-table"
      :checkable="ShowCheckBox"
      infiniteScroll
      ref="table"
      @checkAll="checkAll"
      @setTotalItems="(data) => $emit('setTotalItems', data)"
      totalItemsCounter
      :updateInterval="updateInterval"
    >
      <div class="flex f-jsb f-ai options-header">
        <div class="flex icons">
          <DeliveryStatusUI type="rush" component="simple-text" />
          <DeliveryStatusUI type="priority" component="simple-text" />
          <DeliveryStatusUI type="refrigerated" component="simple-text" />
          <DeliveryStatusUI type="leave-at-door" component="simple-text" />
          <DeliveryStatusUI type="collect" component="simple-text" />
          <DeliveryStatusUI type="male-driver" component="simple-text" />
          <DeliveryStatusUI type="female-driver" component="simple-text" />
        </div>
        <div class="flex f-ai">
          <!-- Temporally hidden -->
          <Button
            class="mr-2"
            :label="$t('deliveries.assignSelected')"
            type="is-primary"
            icon-left="arrow-right-bold-box"
            @click="onAssignDelivery(checkedRows, false, true)"
            :permission="Permission.assign"
            :disabled="
              oDisabled || oLoading.rowId != 0 || oLoading.delete || checkedRows.length == 0
            "
            :loading="oLoading.multipleAssing"
            outlined
          />
          <Button
            :label="$t('deliveries.deleteSelected')"
            type="is-danger"
            icon-left="delete"
            @click="onDeleteMultipleDeliveries"
            :permission="Permission.delete"
            :disabled="oLoading.rowId != 0 || oLoading.delete || checkedRows.length == 0"
            :loading="oLoading.delete"
            outlined
          />
          <CDPFilter class="ml-2" v-model="cdpIdFilter" isRadio />
        </div>
      </div>
      <b-table-column :label="$t('global.order')" width="240" v-slot="props" :td-attrs="tdAttrs">
        <div class="flex f-wrap cell-order icons-table">
          <DeliveryIcons
            :status="{
              rush: props.row.rush,
              priority: props.row.priority,
              refrigerated: props.row.refrigeration_needed,
              leaveAtDoor: props.row.leave_at_door,
              collect: props.row.is_collect_event,
              male_driver: props.row.male_driver,
              female_driver: props.row.female_driver
            }"
          />

          <span> {{ props.row.order_number }} </span>
          <CopaymentTag :value="props.row.copayment_amount" />
          <br />
          <template v-if="props.row.canceled">
            <div class="cancelled">
              <span> {{ $t('schedule.cancelled') }}</span>
            </div>
          </template>
        </div>
      </b-table-column>
      <b-table-column :label="$t('global.date')" v-slot="props" width="130" :td-attrs="tdAttrs">
        <DateTime class="f-jc" :date="props.row.delivery_date" format="MM/DD/Y" />
        <ConstraintDatesViewer
          isTag
          :offset="props.row.offset_tz"
          :startTime="props.row.delivery_start_time"
          :endTime="props.row.delivery_end_time"
        />
      </b-table-column>
      <b-table-column :label="$tc('participant.label')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.participant_full_name }}
      </b-table-column>
      <b-table-column :label="$t('schedule.delivery')" v-slot="props" :td-attrs="tdAttrs">
        <div
          class="cdp-color"
          v-if="props.row.cdp_color"
          :style="{ background: props.row.cdp_color }"
        />
        <div class="deliveryAddress" :style="{ borderColor: props.row.cdp_color }">
          <div>
            <strong>{{ props.row.participant_full_address || '-' }}</strong>
            <p>{{ props.row.cdp_full_address || '-' }}</p>
          </div>
        </div>
      </b-table-column>
      <b-table-column v-slot="props" :td-attrs="tdAttrs">
        <div class="flex">
          <Link
            :label="$t('button.showMap')"
            icon="map-marker-radius"
            @click="() => onShowMarkerOnMap(props.row)"
            :permission="Permission.showOnMap"
            autoLoading
          />
          <Link
            :label="$t('schedule.assignDelivery')"
            @click="() => onAssignDelivery(props.row)"
            class="dropdown"
            icon="arrow-right-bold-box"
            :disabled="!isAsignAllowed || !!props.row.canceled"
            :loading="oLoading.rowId == props.row.id"
            :permission="Permission.assign"
          />
          <Dropdown
            v-if="moreActionsDropdown.length > 0"
            :label="$t('global.moreActions')"
            :maxHeight="200"
            :loading="props.row.id == cancelingId"
            position="is-bottom-left"
            icon="dots-horizontal"
            :showLabel="false"
            @click="(value) => onClick(value, props.row)"
            :disabled="oLoading.rowId != 0 || oLoading.delete"
            :items="moreActionsDropdown(props.row.canceled)"
          />
        </div>
      </b-table-column>
    </Table>
  </div>
</template>

<script>
import { Permissions } from '@/utils/Secure';
import { toast, confirm } from '@/utils/dialog';
import RSC from '@/utils/RequestStatusCodes';
import { Deliveries } from '@/views/Fragments';
import {
  Button,
  DateTime,
  Dropdown,
  Link,
  Table,
  ConstraintDatesViewer,
  DeliveryStatusUI,
  DeliveryIcons,
  CopaymentTag
} from '@/components';
import { find, head, propEq, propOr } from 'ramda';
import { dashboardUpdateCommit } from '../../store/commits';
import CDPFilter from '../CDP/CDPFilter.vue';

export default {
  components: {
    CopaymentTag,
    Button,
    DateTime,
    Deliveries,
    Dropdown,
    Link,
    Table,
    ConstraintDatesViewer,
    DeliveryStatusUI,
    DeliveryIcons,
    CDPFilter
  },
  created() {
    this.unsubscribe = this.$store.subscribe(
      ({ type }, { schedule: { currentManifest }, dispatch: { manifest } }) => {
        if (type === 'deliveries/reloadTable') this.fullReload();
        if (type == 'schedule/currentManifest') {
          this.manifest = currentManifest;
        }
        if (this.dispatchView && type == 'dispatch/manifest') {
          this.manifest = manifest;
        }
      }
    );
  },
  destroyed() {
    this.onShowMarkerOnMap(); //Remove marker from map
    this.unsubscribe();
  },
  data() {
    return {
      checkedRows: [],
      oDisabled: false,
      editRowData: null,
      oLoading: { delete: false, table: this.loading, rowId: 0, multipleAssing: false },
      manifest: null,
      cdpIdFilter: null,
      showDeliveryModal: false,
      unsubscribe: null,
      cancelingId: null,
      updateInterval: 60
    };
  },
  computed: {
    cdpColors() {
      return this.$store.getters.cdpColors;
    },
    isAsignAllowed() {
      return !this.oDisabled && this.manifest?.id && !this.oLoading.rowId && !this.oLoading.delete;
    },
    ShowCheckBox() {
      let isAllowed = false;
      if (Permissions.Scheduling.assign?.length) {
        isAllowed = Permissions.Scheduling.assign.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(Permissions.Scheduling.assign);
    },
    bTable() {
      return this.$refs?.table?.bTable;
    },
    aParams() {
      let aParams = [];
      Object.keys(this.params).map((key) => {
        let value = this.params[key];
        if (key == 'delivery_date') value += '  00:00:00';
        if (value) aParams.push(`${key}=${value}`);
      });
      if (this.cdpIdFilter) aParams.push(`close_door_pharmacy_id=${this.cdpIdFilter}`);

      return aParams;
    },
    Permission() {
      return Permissions.Scheduling;
    }
  },
  methods: {
    moreActionsDropdown(isCanceled) {
      const actions = [
        {
          value: this.$t('schedule.deleteDelivery'),
          event: 'onDeleteDelivery',
          permission: this.Permission.delete
        }
      ];
      if (!isCanceled) {
        return [
          {
            value: this.$t('schedule.editDelivery'),
            event: 'onEditDelivery',
            permission: this.Permission.update
          },
          ...actions,
          {
            value: this.$t('schedule.cancelDelivery'),
            event: 'onCancelDelivery',
            permission: this.Permission.cancel
          }
        ];
      }
      return actions;
    },
    checkAll(rows) {
      this.checkedRows = rows.filter((row) => this.isCheckable(row, rows));
    },
    isCheckable(data, rows) {
      if (data.canceled) return false;
      const firstCheckedRow = head(rows || this.checkedRows);
      if (firstCheckedRow) return data.is_collect_event === firstCheckedRow.is_collect_event;
      return true;
    },
    async getData(data) {
      this.updateInterval = 0;
      await this.$refs.table.fullReload(data);
      this.updateInterval = 60;
    },
    async fullReload(showLoader = true) {
      await this.$refs.table.fullReload({ showLoader });
    },
    searchApiUrl(urlParams) {
      const hasAllParam = !!urlParams?.find((urlParam) => urlParam.indexOf('all=') >= 0);
      return `delivery_manifest/unattended_deliveries${hasAllParam ? '/search' : ''}`;
    },
    deliveriesDataPreProcessor(deliveries) {
      let pDeliveries = deliveries;
      if (pDeliveries[0]?._source) pDeliveries = deliveries.map((RD) => RD._source);

      pDeliveries = pDeliveries.map((delivery) => {
        const colorFinder = find(propEq('id', delivery.close_door_pharmacy_id), this.cdpColors);
        const cdp_color = propOr(null, 'color', colorFinder);

        return { ...delivery, cdp_color };
      });

      return pDeliveries;
    },
    isReadOnly({ delivery_manifest_status_id: status_id }) {
      return !this.dispatchView && [3, 4].includes(status_id);
    },
    onClick(value, data) {
      this[value.event](data);
    },
    confirmMessage(baseMessage, delivery, isMultiple = false, error) {
      let message = `<div>${baseMessage}</div>`;
      if (delivery && !isMultiple) {
        const { order_number, participant_full_name, participant_full_address } = delivery;
        message = `<table>
            <tr><td><strong>Order:</strong></td><td>${order_number || '-'}</td></tr>
            <tr><td><strong class="mr-2">Participant: </strong></td><td>${participant_full_name}<td></td></tr>
            <tr><td><strong>Address:</strong></td><td>${participant_full_address}</td></tr>
            </table>`;
      } else if (isMultiple) {
        message = `<div>
          <div class="mb-4" >${
            error ? this.$t('schedule.multipleMessageOvertime') : baseMessage
          }</div>
          ${error ? `<strong>* ${error}<strong>` : ''}
          <div class="mt-4" >${this.$t('schedule.multipleProceed')}<div>
        </div>`;
      } else {
        message = `
        <h3><strong>Warning</strong></h3>
        <br>
        <p>${this.$t('schedule.cancelOption')}</p>`;
      }
      return message;
    },
    obtainDeliverysForType(delivery) {
      let collectsDeliverys = [];
      let deliverys = [];

      let allDeliverys = delivery.map((row) => {
        row.is_collect_event === 1
          ? (collectsDeliverys = [...collectsDeliverys, row.id])
          : (deliverys = [...deliverys, row.id]);

        return row.id;
      });

      return { deliverys, collectsDeliverys, allDeliverys };
    },
    onShowMarkerOnMap(delivery) {
      const payload = delivery
        ? {
            lat: delivery?.participant_latitude,
            lng: delivery?.participant_longitude,
            participant_full_address: delivery?.participant_full_address,
            participant_full_name: delivery?.participant_full_name,
            order_number: delivery?.tracking_number
          }
        : undefined;
      this.$store.dispatch('map/showMarker', payload);
    },
    async onAssignDelivery(delivery, isOverride, isMultiple, isAddOn, ignoreDeliverys = false) {
      if (isMultiple) {
        if (delivery.length > 8) {
          toast('warning', this.$t('schedule.unassignedExceeded'), 5000);
          return;
        }
      }

      const currentDeliverys = isMultiple
        ? this.obtainDeliverysForType(delivery)
        : {
            unattended_delivery_id: delivery.id,
            delivery_event_type_id: delivery.is_collect_event === 1 ? 8 : 3
          };

      if (isMultiple) this.oLoading.multipleAssing = true;
      else this.oLoading.rowId = currentDeliverys?.unattended_delivery_id;

      try {
        const url = `delivery_manifest/${this.manifest.id}/${
          this.dispatchView || isAddOn ? 'unattended_' : ''
        }delivery_events${isOverride ? '_override' : ''}`;
        if (isMultiple) {
          const { deliverys, collectsDeliverys, allDeliverys } = currentDeliverys;

          let deliverysDataCurrent = [];
          let collectDeliveryDataCurrent = [];

          if (deliverys.length >= 1 && !ignoreDeliverys) {
            const { data: deliveryData } = await this.Api.post(url, {
              delivery_event_type_id: 3,
              unattended_delivery_id: deliverys
            });
            deliverysDataCurrent = deliveryData;
          }

          if (collectsDeliverys.length >= 1) {
            const { data: collectDeliveryData } = await this.Api.post(url, {
              delivery_event_type_id: 8,
              unattended_delivery_id: collectsDeliverys
            });
            collectDeliveryDataCurrent = collectDeliveryData;
          }

          let data = [...deliverysDataCurrent, ...collectDeliveryDataCurrent];
          allDeliverys.map((delivery) =>
            this.$refs.table.removeRow({ key: 'id', value: delivery })
          );
          this.checkedRows = [];
          this.$store.commit('schedule/assignedDelivery', data);
        } else {
          const { data } = await this.Api.post(url, currentDeliverys);
          this.$store.commit('schedule/assignedDelivery', data);
        }

        toast('success', this.$t('schedule.messages.assigned'), 5000);
        this.$store.commit('dispatch/reloadTable');
        dashboardUpdateCommit();
        this.fullReload();
      } catch (error) {
        const errorData = propOr(error, 'data', error);
        const { status_code } = errorData || {};
        let errorText = error?.data?.text || '';
        errorText = errorText && typeof errorText === 'string' ? errorText : errorText[0];
        if (status_code === RSC.manifest_force_overtime) {
          confirm({
            message: this.confirmMessage(
              this.$t('schedule.confirm.overtime'),
              delivery,
              isMultiple,
              errorText
            ),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.yes'),
            onConfirm: async () => {
              this.onAssignDelivery(delivery, true, isMultiple, false, true);
            }
          });
        }
        if (status_code === RSC.manifest_force_as_addon) {
          confirm({
            message: this.confirmMessage(
              this.$t('schedule.confirm.manifestRunning'),
              delivery,
              isMultiple
            ),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.yes'),
            onConfirm: async () => {
              this.onAssignDelivery(delivery, false, isMultiple, true);
            }
          });
        }
      }
      this.oLoading.rowId = 0;
      this.oLoading.multipleAssing = false;
    },
    async onCancelDelivery(delivery) {
      confirm({
        message: this.confirmMessage(this.$t('confirms.cancelDelivery')),
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('confirm.yes'),
        onConfirm: async () => {
          try {
            this.cancelingId = delivery.id;
            await this.Api.patch(`delivery_manifest/unattended_deliveries/${delivery.id}/cancel`);
            this.getData();
            dashboardUpdateCommit();
            toast('success', this.$t('schedule.messages.canceled'), 5000);
          } catch (error) {
            console.error(error);
          } finally {
            this.cancelingId = null;
          }
        }
      });
    },
    async onDeleteDelivery(delivery) {
      confirm({
        message: this.confirmMessage(this.$t('confirms.deleteDelivery'), delivery),
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('confirm.yes'),
        onConfirm: async () => {
          try {
            await this.Api.delete(`delivery_manifest/unattended_deliveries/${delivery.id}`);
            this.$refs.table.removeRow({ key: 'id', value: delivery.id });
            this.$store.commit('map/update', { action: 'dashboardUpdate' });
            dashboardUpdateCommit();
            toast('success', this.$t('schedule.messages.deleted'), 5000);
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
    async onDeleteMultipleDeliveries() {
      confirm({
        title: this.$t('confirms.continue'),
        message: this.$t('confirms.deleteMultipleUnassignedDeliveries'),
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('confirm.yes'),
        onConfirm: async () => {
          try {
            this.oLoading.delete = true;
            let checkedRowsIds = this.checkedRows.map((row) => row.id);
            await this.Api.delete('/delivery_manifest/unattended_deliveries', {
              unattended_deliveries: checkedRowsIds
            });
            checkedRowsIds.map((delivery) =>
              this.$refs.table.removeRow({ key: 'id', value: delivery })
            );
            toast(
              'success',
              this.checkedRows.length > 1
                ? this.$t('schedule.messages.multipleDeleted')
                : this.$t('schedule.messages.deleted'),
              5000
            );
            dashboardUpdateCommit();
            this.checkedRows = [];
          } catch (error) {
            console.error(error);
          }
          this.oLoading.delete = false;
        }
      });
    },
    onEditDelivery(row) {
      this.editRowData = row;
      this.showDeliveryModal = true;
    },
    columnClassByRow(row = {}) {
      let classes = [];
      if (!row.is_active) classes.push('inactive');
      if (row.isOpen) classes.push('isOpen');
      return classes.join(' ');
    },
    tdAttrs(row) {
      return { class: this.columnClassByRow(row) };
    },
    toggleDetails(row) {
      row.isOpen = !row.isOpen;
      this.bTable.toggleDetails(row);
    }
  },
  watch: {
    showDeliveryModal(value) {
      if (!value) this.editRowData = {};
    },
    disabled(value) {
      this.oDisabled = value;
    },
    loading(value) {
      this.checkedRows = [];
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    },
    manifest(value) {
      if (value) this.oDisabled = this.isReadOnly(value);
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    params: { type: Object, default: () => {} },
    dispatchView: { type: Boolean, default: false }
  }
};
</script>
<style lang="sass" scoped>
#unattended-deliveries-table
  margin-top: -55px
  border-radius: $br-md
  max-height: 100%
  .table-container
    min-height: calc(100% - 120px)
.copayment-tag
  width: 100%
.cancelled
  background: $red-200
  border-radius: 20px
  text-align: center
  color: $red-700
  padding: 0.2rem 0.4rem
  margin-left: 0.5rem
.cell
  &-icons
    display: flex
    align-items: flex-start
    ::v-deep
      .icon
        height: 25px
  &-order
    word-break: break-word
    width: 100%
.schedule-table
  .icons
    .simple-delivery ::v-deep
      i:before
        transform: translate(-55%, -17px) !important
    .simple-delivery:nth-child(3), .simple-delivery:nth-child(5)
      ::v-deep
        i::before
          transform: translate(-55%, -12px) !important
  .options-header
    margin: 0rem 0 1rem 0
    .refrigerated
      display: flex
      align-items: center
      ::v-deep
        .refrigerated-text
          font-size: 14px
        .refrigerated-icon
          width: 32px
          font-size: 16.67px
          color: $blue-700
  ::v-deep
    td:nth-child(5)
      position: relative
  .deliveryAddress
    display: flex
    font-size: $f-sm
    position: relative
  .cdp-color
    position: absolute
    left: 0px
    top: 10px
    bottom: 10px
    width: 7px
    border-radius: 5px
    margin-right: 5px
  .icons-table
    ::v-deep
      .icons
        .simple-delivery .icon
          i:before
            transform: translate(-48%, -17px) !important
        .simple-delivery__collect .icon,  .simple-delivery__refrigerated .icon
          i:before
            transform: translate(-48%, -13px) !important
  ::v-deep
    th
      z-index: 39!important
    tr
      .isOpen:first-child,
      .isOpen:last-child
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
    .table-wrapper
      height: calc(100vh - 320px)
@media only screen and (max-width: $bp-xl)
  #unattended-deliveries-table
    margin-top: 0
    ::v-deep .table-title
      display: none
  .schedule-table
    ::v-deep
      .table-wrapper
        max-height: calc(100vh - 380px)
.w-130
  width: 130px
.w-140
  width: 140px
.w-250
  width: 250px
</style>

<style lang="sass">
.dark
  .schedule-table
    strong
      color: $main-background
</style>
